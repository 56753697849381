import { Route, Routes, useSearchParams } from "react-router";
import { Settings } from "../Settings/Settings";
import { StudyInfo } from "../StudyInfo/StudyInfo";
import { MainLayout } from "../Layout/MainLayout";
import { PublicLayout } from "../Layout/PublicLayout";
import { NotFound } from "../NotFound/NotFound";
import { EventList } from "../Events/EventList/EventList";
import { UnscheduledEventList } from "../Events/UnscheduledEventList/UnscheduledEventList";
import { ValidatePageIdForm } from "../ValidatePageIdForm/ValidatePageIdForm";
import { ChangePin } from "../ChangePin/ChangePin";
import { Home } from "../Home/Home";
import { Pdf } from "../Pdf/Pdf";
import { Welcome } from "../Welcome/Welcome";
import { ProtectedRoute } from "components/Base/ProtectedRoute/ProtectedRoute";
import { SigninCallback } from "components/SigninCallback/SigninCallback";
import { Login } from "components/Login/Login";
import { SignoutCallback } from "components/SignoutCallback/SignoutCallback";
import { withAuthenticationRequired } from "react-oidc-context";
import { useMemo } from "react";
import { getAuthenticationContextReference } from "helpers/getAuthenticationContextReference";

export const ApplicationRoutes = () => {
  const [searchParams] = useSearchParams();
  const loginHint = searchParams.get("login_hint");
  const acrValues = getAuthenticationContextReference(searchParams.get("authm"), searchParams.get("locale"));

  const RequireAuth = useMemo(() => {
    const signinRedirectArgs = {
      ...(loginHint && { login_hint: loginHint }),
      ...(acrValues && { acr_values: acrValues }),
    };

    return withAuthenticationRequired(ProtectedRoute, { signinRedirectArgs });
  }, [loginHint, acrValues]);

  return (
    <Routes>
      <Route path="/signout-oidc" element={<SignoutCallback />} />
      <Route path="/signin-oidc" element={<SigninCallback />} />
      <Route path="/login" element={<Login />} />
      <Route path="/pdf/:formId" element={<Pdf />} />
      <Route element={<PublicLayout />}>
        <Route element={<RequireAuth />}>
          <Route path="/change-pin" element={<ChangePin />} />
          <Route path="/welcome" element={<Welcome />} />
        </Route>
      </Route>
      <Route path="/" element={<MainLayout />}>
        <Route element={<RequireAuth />}>
          <Route index element={<Home />} />
          <Route path="study" element={<StudyInfo />} />
          <Route path="settings" element={<Settings />} />
          <Route path="events">
            <Route index element={<EventList />} />
            <Route
              path=":eventId/form/:page"
              element={<ValidatePageIdForm />}
            />
          </Route>
          <Route path="unscheduled-events">
            <Route index element={<UnscheduledEventList />} />
            <Route
              path=":eventId/form/:page"
              element={<ValidatePageIdForm />}
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
