import useSWR from "swr";
import { http } from "services/http";

export const useFetchStudyFile = (fileGuid, onSuccess) => {
  const {
    data: studyFile,
    error,
    isLoading,
  } = useSWR(fileGuid ? `/api/studyfile/${fileGuid}` : null, http.get, {
    revalidateOnFocus: false,
    onSuccess,
  });

  return { studyFile, isLoading, error };
};
