import { eraseCookie } from "helpers/cookies";
import {
  broadcastChannelManager,
  BROADCAST_MESSAGE,
} from "broadcastChannel/broadcastChannelManager";
import { trackAppInsightsTelemetry, TRACK_METHOD } from "logging/applicationInsightsService";

export const handleSignoutCallback = async (response) => {

  try {
    broadcastChannelManager.sendMessage(BROADCAST_MESSAGE.LOGOUT);
  } catch (error) {
    trackAppInsightsTelemetry(error, TRACK_METHOD.EXCEPTION);
  }

  eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);

  const userState = response?.userState;

  const path = userState
    ? `/?authm=${userState.idp}${userState.locale ? `&locale=${userState.locale}` : ''}`
    : "/";

  window.location.href = path;
};
