import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useFetchStudyInfo } from "hooks/api/study/useFetchStudyInfo";
import { LoadingContext, actionTypes } from "context/LoadingContext";

import { Box } from "components/Base/Box/Box";
import { BoxSection } from "components/Base/Box/BoxSection/BoxSection";
import { FetchError } from "components/Base/FetchError/FetchError";

import "./StudyInfo.css";
import { BoxHeader } from "components/Base/Box/BoxHeader/BoxHeader";
import { StaggeredAnimation } from "components/Base/Box/StaggeredAnimation/StaggeredAnimation";

export function StudyInfo() {
  const { t } = useTranslation();
  const { dispatch } = useContext(LoadingContext);

  const { studyInfo, isLoading, error } = useFetchStudyInfo();

  const getInfo = (key) => !isLoading && !error && studyInfo[key];

  useEffect(() => {
    const LOADING_KEY = "study-info";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="study-info">
      <h1 className="study-info__title">{t("study_info.title")}</h1>
      <Box>
        {error && (
          <BoxHeader>
            <FetchError />
          </BoxHeader>
        )}
        <StaggeredAnimation animationKey="study-info">
          <BoxSection className="study-info__section">
            <small className="study-info__description">
              {t("study_info.study_name")}
            </small>
            <h2 className="study-info__section-title">
              {getInfo("studyName")}
            </h2>
          </BoxSection>
          <BoxSection className="study-info__section">
            <small className="study-info__description">
              {t("study_info.your_clinic")}
            </small>
            <h2 className="study-info__section-title">
              {getInfo("clinicName")}
            </h2>
          </BoxSection>
        </StaggeredAnimation>
      </Box>
    </div>
  );
}
