import useSWRMutation from "swr/mutation";
import { http } from "services/http";

export const useSendForm = (eventId) => {
  const { trigger: sendForm, isMutating: isSending } = useSWRMutation(
    `/api/quest/${eventId}`,
    http.post,
    { revalidate: false }
  );

  return { sendForm, isSending };
};
