import PropTypes from "prop-types";
import classNames from "classnames";
import { Link as RouterLink } from "react-router";
import { useTranslation } from "react-i18next";
import { DateIcon } from "components/Base/DateIcon/DateIcon";
import { ReactComponent as ArrowIcon } from "content/icons/arrow.svg";
import { EventStatus, isPastEvent } from "components/Events/event-status";
import { DateRange } from "components/DateRange/DateRange";

import "./EventListItem.css";
export function EventListItem({ disabled, event }) {
  const { t, i18n } = useTranslation();

  const getStatusText = () => {
    switch (event.status) {
      case EventStatus.MISSING:
        return t("events.not_submitted");
      case EventStatus.RECEIVED:
        return t("events.submitted");
      case EventStatus.SCHEDULED:
        return t("events.scheduled");
      default:
        return t("events.available");
    }
  };

  const getEventDate = () => {
    if (event.status === EventStatus.MISSING) {
      return;
    }

    return new Date(
      event.status === EventStatus.RECEIVED ? event.submitted : event.target
    );
  };

  const getEventInfo = () => {
    if (event.availableStart && event.availableEnd) {
      return (
        <DateRange
          label={getStatusText()}
          startDate={event.availableStart}
          endDate={event.availableEnd}
          timeEnd={event.timingWindowEnd}
          timeStart={event.timingWindowStart}
        />
      );
    }

    return getStatusText();
  };

  const eventDate = getEventDate();

  return (
    <RouterLink
      className={classNames({
        "event-list-item": true,
        "event-list-item--past": isPastEvent(event),
        "event-list-item--disabled": disabled,
      })}
      to={disabled ? "#" : `${event.formId}/form/1`}
      tabIndex={disabled ? -1 : 0}
    >
      <DateIcon
        active={!isPastEvent(event) && !disabled}
        dayOfMonth={eventDate?.getDate()}
      />
      <div className="event-list-item__data">
        <h4 className="event-list-item__label">{event.formName}</h4>
        <time className="event-list-item__date">
          {eventDate?.toLocaleString(i18n.language, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </time>
        <p className="event-list-item__info">{getEventInfo()}</p>
      </div>
      <ArrowIcon className="event-list-item__icon" />
    </RouterLink>
  );
}

EventListItem.propTypes = {
  disabled: PropTypes.bool,
  event: PropTypes.shape({
    availableStart: PropTypes.string,
    availableEnd: PropTypes.string,
    status: PropTypes.oneOf([
      EventStatus.ACTIVE,
      EventStatus.MISSING,
      EventStatus.RECEIVED,
      EventStatus.SCHEDULED,
    ]),
    formName: PropTypes.string,
    formId: PropTypes.string.isRequired,
    submitted: PropTypes.string,
    target: PropTypes.string,
    timingWindowEnd: PropTypes.string,
    timingWindowStart: PropTypes.string,
  }).isRequired,
};
