import { useParams } from "react-router";
import { Form, FORM_PAGE } from "components/Form/Form";
import { NotFound } from "components/NotFound/NotFound";

// Validates that page is either a number or "submit" or "thank-you"
export function ValidatePageIdForm() {
  const { page } = useParams();

  if (page === FORM_PAGE.SUBMIT) {
    return <Form page={FORM_PAGE.SUBMIT} />;
  }

  if (page === FORM_PAGE.THANK_YOU) {
    return <Form page={FORM_PAGE.THANK_YOU} />;
  }

  const isPageNumber = page && page.match(/\d+/);

  if (!isPageNumber) {
    return <NotFound />;
  }

  return <Form page={page} />;
}
