import "./Home.css";

import { createRef, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useFetchHome } from "hooks/api/home/useFetchHome";
import { LoadingContext, actionTypes } from "context/LoadingContext";

import { Button } from "components/Base/Button/Button";
import { Dashboard } from "./Dashboard/Dashboard";
import { EventCompliance } from "./EventCompliance/EventCompliance";
import { Modal } from "components/Modal/Modal";

export function Home() {
  const { t } = useTranslation();

  const { isLoading, status, error } = useFetchHome();
  const [fetchErrors, setFetchErrors] = useState([]);
  const [showHelp, setShowHelp] = useState(false);
  const { dispatch } = useContext(LoadingContext);
  const showHelpButton = createRef();

  useEffect(() => {
    setFetchError("home.dashboard", !!error);
  }, [error]);

  useEffect(() => {
    const LOADING_KEY = "home";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoading]);

  function openModal() {
    setShowHelp(true);
  }

  function closeModal() {
    showHelpButton.current.focus();
    setShowHelp(false);
  }

  // Add or remove error defined by key to error list and ensure unique
  const setFetchError = (key, hasError) => {
    setFetchErrors((prevErrors) =>
      !!hasError
        ? [...new Set([...prevErrors, key])]
        : prevErrors.filter((val) => val !== key)
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="home">
      <>
        <div className="home__header">
          <h1 className="home__title">
            {t("home.greeting.hello")}
            <br />
            {t("home.greeting.nice")}
          </h1>
          <div className="home__status">
            {status && (
              <EventCompliance
                percent={status.compliancePercent}
                missedCount={status.missedEventsCount}
                studyLocked={status.studyLock}
              />
            )}
          </div>
        </div>
        <Dashboard
          className="home__dashboard"
          setFetchError={setFetchError}
          showFetchError={fetchErrors.length > 0}
          showVideoTile={status && status.hasViedocConnectEnabled}
          studyLocked={status && status.studyLock}
        />
        <Button
          buttonRef={showHelpButton}
          className="home__help-button"
          color="black"
          label={t("general.help.get_help")}
          onClick={openModal}
          size="large"
        />
        <Modal
          isOpen={showHelp}
          onClose={closeModal}
          title={t("general.help.get_help")}
        >
          {t("getHelp.info")}
        </Modal>
      </>
    </div>
  );
}
