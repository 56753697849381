import React, { useEffect, useContext, useRef } from "react";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router";
import { BusyStateContext } from "context/BusyStateContext";
import { useSignOut } from "hooks/useSignout";

const KEEP_USER_ACTIVE_INTERVAL = 10 * 1000; // 10s
const heartBeatUrl = `${process.env.REACT_APP_ME_IDP_URL}/api/heartbeat`;

export function InactivityTracker() {
  const { hasBusyItems } = useContext(BusyStateContext);
  const { isAuthenticated, user } = useAuth();
  const signout = useSignOut();
  const { pathname } = useLocation();
  const inactivityTracker = useRef(null);

  useEffect(() => {
    const tracker = inactivityTracker.current;
    if (tracker) {
      tracker.tokenCallback = () => {
        return user?.access_token;
      };

      tracker.logoutCallback = signout;

      return () => {
        if (tracker) {
          delete tracker.tokenCallback;
          delete tracker.logoutCallback;
        }
      };
    }
  }, [inactivityTracker, signout, user]);

  const isPdfMode = pathname.startsWith("/pdf/");

  if (!isAuthenticated || isPdfMode) {
    return null;
  }

  return (
    <viedoc-inactivity-tracker-cross-domain
      ref={inactivityTracker}
      heartbeat-url={heartBeatUrl}
      is-single-page
      hot-reload
      keep-alive={hasBusyItems || null}
      keep-user-active-interval={
        hasBusyItems ? KEEP_USER_ACTIVE_INTERVAL : null
      }
    ></viedoc-inactivity-tracker-cross-domain>
  );
}
