import { useAuth } from "react-oidc-context";
import { useEffect } from "react";
import { useAsyncError } from "hooks/useAsyncError";
import {
  BROADCAST_MESSAGE,
  broadcastChannelManager,
} from "broadcastChannel/broadcastChannelManager";
import { useSearchParams } from "react-router";
import { getAuthenticationContextReference } from "helpers/getAuthenticationContextReference";

// This component is used for login without a return URL,
// for example when logging in with reference token shared via email or sms,
// and for eID login.
export function Login() {
  const { removeUser, clearStaleState, signinRedirect } = useAuth();
  const [searchParams] = useSearchParams();
  const throwError = useAsyncError();
  const acrValues = getAuthenticationContextReference(
    searchParams.get("authm"),
    searchParams.get("locale")
  );

  useEffect(() => {
    async function loginRedirect() {
      try {
        // Remove current user in case multiple users are using the same device
        await removeUser();
        await clearStaleState();

        // Notify other tabs that previous user is removed
        // This is necessary to prevent the inactivity tracker from triggering a logout due to using the previous users token
        broadcastChannelManager.sendMessage(BROADCAST_MESSAGE.LOGOUT);

        // Redirect to identity provider
        await signinRedirect({
          ...(acrValues && { acr_values: acrValues }),
        });
      } catch (error) {
        throwError(error);
      }
    }

    loginRedirect();
  }, [clearStaleState, removeUser, signinRedirect, throwError, acrValues]);

  return null;
}
