export function splitFileIntoChunks(file, totalSize) {
  const CHUNK_SIZE = 2097152; // 2 * (1024 * 1024) bytes = 2 MB

  let fileStreamPos = 0;
  let endPos = CHUNK_SIZE;
  const fileChunks = [];

  // Add to the file chunk array until we get to the end of the file
  while (fileStreamPos < totalSize) {
    // "slice" the file from the starting position/offset, to the required length
    fileChunks.push(file.slice(fileStreamPos, endPos));
    fileStreamPos = endPos; // jump by the amount read
    endPos = fileStreamPos + CHUNK_SIZE; // set next chunk length
  }

  return fileChunks;
}