import useSWR from "swr";
import { http } from "services/http";

export const useFetchUnscheduledEvents = () => {
  const {
    data: events,
    error,
    isLoading,
  } = useSWR("/api/user/events/unscheduled", http.get);

  return { events, isLoading, error };
};
