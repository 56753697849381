import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { DrawingPad } from "components/Base/DrawingPad/DrawingPad";
import { Button } from "components/Base/Button/Button";
import {
  FileDetails,
  DisplayThumbnail,
} from "components/Base/FileDetails/FileDetails";

import { errorDescribedBy } from "components/Base/ValidationError/errorDescribedBy";
import { ValidationError } from "components/Base/ValidationError/ValidationError";

import "./DrawingPadItem.css";
import { FilePreview } from "components/Base/FilePreview/FilePreview";
import { useDownloadStudyFile } from "hooks/api/studyFile/useDownloadStudyFile";

export function DrawingPadItem({
  ariaDescribedBy,
  layout,
  oid,
  onChange,
  readOnly,
  value,
}) {
  const { t } = useTranslation();
  const drawingPad = useRef(null);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [error, setError] = useState();
  const { download } = useDownloadStudyFile(value?.FileGuid);

  const handleClearClick = () => {
    if (drawingPad.current) {
      drawingPad.current.clear();
    }
  };

  useEffect(() => {
    /* https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript */
    const isTouch =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;

    setIsTouchDevice(isTouch);
  }, []);

  const downloadFile = async () => {
    try {
      await download(value.FileName);
    } catch (err) {
      setError({
        message: err.message ?? t("form.file_upload.download_error"),
      });
    }
  };

  return (
    <div
      className="drawing-pad-item"
      aria-describedby={ariaDescribedBy}
      id={oid}
    >
      {readOnly ? (
        <>
          {error && (
            <ValidationError
              id={errorDescribedBy(oid)}
              message={error.message}
            />
          )}
          <FilePreview>
            {value ? (
              <FileDetails
                fileName={value.FileName}
                fileSize={value.FileSize}
                displayThumbnail={layout.displayThumbnail}
                thumbnail={value.Thumbnail}
                onClick={downloadFile}
              />
            ) : (
              t("form.drawing_pad.placeholder")
            )}
          </FilePreview>
        </>
      ) : (
        <>
          <DrawingPad
            backgroundImageName={layout.background}
            onChange={onChange}
            ref={drawingPad}
            value={value}
          />
          <div className="drawing-pad-item__footer">
            <Button
              color="light-gray"
              label={t("form.drawing_pad.clear")}
              onClick={handleClearClick}
            />
            <p className="drawing-pad-item__info">
              {isTouchDevice
                ? t("form.drawing_pad.mobile_info")
                : t("form.drawing_pad.desktop_info")}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

DrawingPadItem.propTypes = {
  ariaDescribedBy: PropTypes.string,
  layout: PropTypes.shape({
    background: PropTypes.string,
    displayThumbnail: PropTypes.oneOf(Object.values(DisplayThumbnail)),
  }),
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      FileName: PropTypes.string.isRequired,
      FileSize: PropTypes.number.isRequired,
      Thumbnail: PropTypes.string.isRequired,
    }),
  ]),
};
