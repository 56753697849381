import { http } from "services/http";
import useSWR from "swr";

export const useFetchIsRoomActive = (meetingId, refreshInterval, onSuccess) => {
  const fetcher = () =>
    http.post("/api/whereby/isroomactive", { arg: { meetingId } });

  const { data: isRoomActive, error } = useSWR(
    `/api/whereby/isroomactive/${meetingId}`,
    fetcher,
    {
      refreshInterval,
      onSuccess,
    }
  );

  return { isRoomActive, error };
};
