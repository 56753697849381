import { http } from "services/http";
import useSWRMutation from "swr/mutation";

const fetcher = (url, { arg }) =>
  http.postWithProgress(
    url,
    arg.formData,
    arg.progressHandler,
    arg.abortCallback
  );

export const useUploadFile = () => {
  const {
    trigger: upload,
    isMutating: isUploading,
    error,
  } = useSWRMutation("/api/studyfile", fetcher);

  return { upload, isUploading, error };
};
