import { http } from "services/http";
import useSWRMutation from "swr/mutation";

const fetcher = (url, { arg }) =>
  http.post(`/api/studyfile/${arg.uuid}/merge-chunks`, {
    arg: arg.formData,
  });

export const useMergeChunks = () => {
  const {
    trigger: mergeChunks,
    isMutating: isMerging,
    error,
  } = useSWRMutation(`/api/studyfile/mergechunks`, fetcher);

  return { mergeChunks, isMerging, error };
};
