import { http } from "services/http";
import useSWR from "swr";

export const useFetchSettings = () => {
  const {
    data: accountSettings,
    error,
    isLoading,
  } = useSWR("/api/home/settings", http.get);

  return { accountSettings, isLoading, error };
};
