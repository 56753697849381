import { http } from "services/http";
import useSWR from "swr";

export const useFetchStudyInfo = () => {
  const {
    data: studyInfo,
    error,
    isLoading,
  } = useSWR("/api/home/studyinfo", http.get);

  return { studyInfo, isLoading, error };
};
