import "./Settings.css";

import React, { useEffect, useState, useContext } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { useFetchLanguages } from "hooks/api/study/useFetchLanguages";
import { useFetchSettings } from "hooks/api/settings/useFetchSettings";
import { LoadingContext, actionTypes } from "context/LoadingContext";

import { Box } from "components/Base/Box/Box";
import { BoxHeader } from "components/Base/Box/BoxHeader/BoxHeader";
import { BoxSection } from "components/Base/Box/BoxSection/BoxSection";
import { Button } from "components/Base/Button/Button";
import { CheckBox } from "components/Base/CheckBox/CheckBox";
import {
  emailFormat,
  phoneNumberFormat,
  pinCodeFormat,
} from "helpers/format-helpers";
import { Input } from "components/Base/Input/Input";
import { Select } from "components/Base/Select/Select";
import { StatusMessage } from "components/Base/StatusMessage/StatusMessage";
import { FetchError } from "components/Base/FetchError/FetchError";
import { StaggeredAnimation } from "components/Base/Box/StaggeredAnimation/StaggeredAnimation";
import { useSendSettings } from "hooks/api/settings/useSendSettings";

export function Settings() {
  const { t } = useTranslation();
  const { dispatch } = useContext(LoadingContext);

  const {
    accountSettings,
    error: fetchSettingsError,
    isLoading: isLoadingSettings,
  } = useFetchSettings();

  const {
    accountLanguages,
    error: fetchLanguageError,
    isLoading: isLoadingLanguages,
  } = useFetchLanguages();

  const { sendSettings } = useSendSettings();

  const [settings, setSettings] = useState({
    userName: null,
    newPinCode: "",
    newPinCodeRepeat: "",
    language: "",
    email: "",
    phone: "",
    sendRemindersToEmail: false,
    sendRemindersToPhone: false,
  });

  const [errors, setErrors] = useState({
    general: null,
    pinCode: null,
    phoneNumber: null,
  });

  const [success, setSuccess] = useState("");

  const [showSaveButton, setShowSaveButton] = useState(false);

  useEffect(() => {
    const LOADING_KEY = "settings";
    const type =
      isLoadingLanguages || isLoadingLanguages
        ? actionTypes.ADD_LOADING_ITEM
        : actionTypes.REMOVE_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, isLoadingLanguages, isLoadingSettings]);

  // Override default state values with account settings
  useEffect(() => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...accountSettings,
    }));
  }, [accountSettings]);

  // Clear pin code error on pin code change
  useEffect(() => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      general: null,
      pinCode: null,
    }));
  }, [settings.newPinCode, settings.newPinCodeRepeat]);

  // Clear phone number error on phone number change
  useEffect(() => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      general: null,
      phoneNumber: null,
    }));
  }, [settings.phone]);

  // Clear email error on email change
  useEffect(() => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      general: null,
      email: null,
    }));
  }, [settings.email]);

  // Clear pin codes and errors on successful update
  useEffect(() => {
    if (!!success) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        newPinCode: "",
        newPinCodeRepeat: "",
      }));
      setErrors({
        general: null,
        email: null,
        phoneNumber: null,
        pinCode: null,
      });
    }
  }, [success]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [id]: value,
    }));

    // Reset success message if any
    if (!!success) {
      setSuccess("");
    }

    // Reset general error if any
    if (!!errors.general) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: null,
      }));
    }
  };

  const handleCheckBoxChange = (e) => {
    const { id, checked } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [id]: checked,
    }));
  };

  const remoteErrorMessage = async (e) => {
    try {
      return await e.text();
    } catch (err) {
      return e.message ?? t("settings.submit_failed");
    }
  };

  const saveSettings = async (e) => {
    e.preventDefault();

    window.scrollTo(0, 0);

    const phoneNumber = (settings.phone ?? "")
      .replaceAll("-", "")
      .replaceAll(" ", "");

    const invalidPinCode = !validatePinCodes();
    const invalidEmail =
      hasEmailChanged(settings.email) && !validateEmail(settings.email);
    const invalidPhoneNumber =
      hasPhoneNumberChanged(phoneNumber) && !validatePhoneNumber(phoneNumber);
    const invalidReminderEmail = !validateSendRemindersToEmail(
      settings.email,
      settings.sendRemindersToEmail
    );
    const invalidReminderPhone = !validateSendRemindersToPhone(
      settings.phone,
      settings.sendRemindersToPhone
    );

    if (
      invalidPinCode ||
      invalidEmail ||
      invalidPhoneNumber ||
      invalidReminderEmail ||
      invalidReminderPhone
    ) {
      return false;
    }

    const LOADING_KEY = "save-settings";

    dispatch({
      type: actionTypes.ADD_LOADING_ITEM,
      key: LOADING_KEY,
    });

    try {
      await sendSettings({
        ...settings,
        phone: phoneNumber,
        trySaveReminderEmailSettings:
          accountSettings.allowedChangeReminderEmailSettings,
        trySaveReminderPhoneSettings:
          accountSettings.allowedChangeReminderPhoneSettings,
      });

      if (!!settings.language && i18n.language !== settings.language) {
        await i18n.changeLanguage(settings.language);
      }
      if (settings.phone !== phoneNumber) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          phone: phoneNumber,
        }));
      }
      setSuccess(t("settings.submit_success"));
    } catch (err) {
      const msg = await remoteErrorMessage(err);
      setErrors({
        general: msg,
        pinCode: null,
        phoneNumber: null,
        email: null,
      });
    } finally {
      dispatch({
        type: actionTypes.REMOVE_LOADING_ITEM,
        key: LOADING_KEY,
      });
    }
  };

  const hasEmailChanged = (email) => {
    return email !== accountSettings.email;
  };

  const hasPhoneNumberChanged = (phoneNumber) => {
    return phoneNumber !== accountSettings.phone;
  };

  const validatePinCodes = () => {
    if (
      settings.newPinCode !== "" &&
      !settings.newPinCode.match(pinCodeFormat)
    ) {
      setErrors({
        general: t("settings.errors.pin_code"),
        pinCode: t("change_pin.valid_format", { pinCodeDigits: 4 }),
      });
      return false;
    }

    if (settings.newPinCode !== settings.newPinCodeRepeat) {
      setErrors({
        general: t("settings.errors.pin_code"),
        pinCode: t("change_pin.no_match"),
      });
      return false;
    }

    return true;
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (
      phoneNumber === null ||
      phoneNumber === "" ||
      phoneNumber.match(phoneNumberFormat)
    ) {
      return true;
    }

    setErrors({
      general: t("settings.errors.phone"),
      phoneNumber: t("settings.phone_invalid"),
    });
    return false;
  };

  const validateEmail = (email) => {
    if (email === null || email === "" || email.match(emailFormat)) {
      return true;
    }

    setErrors({
      general: t("settings.errors.email"),
      email: t("settings.email_invalid"),
    });
    return false;
  };

  const validateSendRemindersToEmail = (email, sendRemindersToEmail) => {
    if (sendRemindersToEmail && (email === null || email === "")) {
      setErrors({
        general: t("settings.errors.email"),
        email: t("settings.reminder_email_invalid"),
      });

      return false;
    }

    return true;
  };

  const validateSendRemindersToPhone = (phoneNumber, sendRemindersToPhone) => {
    if (sendRemindersToPhone && (phoneNumber === null || phoneNumber === "")) {
      setErrors({
        general: t("settings.errors.phone"),
        phoneNumber: t("settings.reminder_phone_invalid"),
      });

      return false;
    }

    return true;
  };

  const languageToOption = (language) => {
    return {
      value: language.name,
      label: language.displayName,
      disabled: false,
    };
  };

  if (isLoadingSettings || isLoadingLanguages) {
    return null;
  }

  return (
    <section className="settings">
      <form onSubmit={saveSettings} aria-label={t("settings.settings")}>
        <Box
          footer={
            <div className="settings__button-wrapper">
              <Button size="large" color="green" label={t("settings.submit")} />
            </div>
          }
          showFooter={showSaveButton}
        >
          <BoxHeader>
            {fetchLanguageError || fetchSettingsError ? (
              <FetchError />
            ) : (
              <>
                {!!errors.general || !!success ? (
                  <StatusMessage
                    id="settings-status-message"
                    message={errors.general ?? success}
                    isError={!!errors.general}
                    isSuccess={!!success}
                  />
                ) : (
                  <h1 className="settings__title">{t("settings.settings")}</h1>
                )}
              </>
            )}
          </BoxHeader>
          <StaggeredAnimation
            animationKey="settings"
            onAnimationEnd={() => setShowSaveButton(true)}
          >
            <BoxSection>
              <label className="settings__label" htmlFor="userId">
                {t("settings.user_id")}
              </label>
              <div id="userId" className="settings__highlighted-value">
                {settings.userName}
              </div>
            </BoxSection>
            <BoxSection>
              <div className="settings__current-pin">
                {t("general.pin_code")}
              </div>
              <div className="settings__form-row">
                <Input
                  id="newPinCode"
                  value={settings.newPinCode}
                  type="password"
                  placeholder={t("change_pin.new_pin")}
                  onChange={handleChange}
                  ariaLabel={t("change_pin.new_pin")}
                  valid={!errors.pinCode}
                />
                <Input
                  id="newPinCodeRepeat"
                  value={settings.newPinCodeRepeat}
                  type="password"
                  placeholder={t("change_pin.repeat_new_pin")}
                  onChange={handleChange}
                  ariaLabel={t("change_pin.repeat_new_pin")}
                  valid={!errors.pinCode}
                />
              </div>
              {!!errors.pinCode && (
                <div className="settings__error">{errors.pinCode}</div>
              )}
            </BoxSection>
            <BoxSection>
              <label className="settings__label" htmlFor="language">
                {t("settings.language")}
              </label>
              <Select
                id="language"
                value={settings.language}
                options={(accountLanguages?.languages ?? []).map((language) =>
                  languageToOption(language)
                )}
                onChange={(val) =>
                  handleChange({ target: { id: "language", value: val } })
                }
              />
            </BoxSection>
            {accountSettings?.allowedChangeReminderEmailSettings && (
              <BoxSection>
                <label className="settings__label" htmlFor="email">
                  {t("settings.email")}
                </label>
                <Input
                  id="email"
                  value={settings.email}
                  placeholder={t("settings.email_placeholder")}
                  onChange={handleChange}
                  ariaLabel={t("settings.email_placeholder")}
                />
                {!!errors.email && (
                  <div className="settings__error">{errors.email}</div>
                )}
              </BoxSection>
            )}
            {accountSettings?.allowedChangeReminderPhoneSettings && (
              <BoxSection>
                <label className="settings__label" htmlFor="phone">
                  {t("settings.phone")}
                </label>
                <Input
                  id="phone"
                  value={settings.phone}
                  type="tel"
                  placeholder={t("settings.phone_placeholder")}
                  onChange={handleChange}
                  ariaLabel={t("settings.phone_placeholder")}
                />
                {!!errors.phoneNumber && (
                  <div className="settings__error">{errors.phoneNumber}</div>
                )}
              </BoxSection>
            )}
            {(accountSettings?.allowedChangeReminderEmailSettings ||
              accountSettings?.allowedChangeReminderPhoneSettings) && (
              <BoxSection>
                {t("settings.reminder")}
                {accountSettings.allowedChangeReminderEmailSettings && (
                  <CheckBox
                    id="sendRemindersToEmail"
                    label={t("settings.reminder_email")}
                    checked={settings.sendRemindersToEmail}
                    onChange={handleCheckBoxChange}
                  />
                )}
                {accountSettings.allowedChangeReminderPhoneSettings && (
                  <CheckBox
                    id="sendRemindersToPhone"
                    label={t("settings.reminder_phone")}
                    checked={settings.sendRemindersToPhone}
                    onChange={handleCheckBoxChange}
                  />
                )}
              </BoxSection>
            )}
          </StaggeredAnimation>
        </Box>
      </form>
    </section>
  );
}
