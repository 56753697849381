import PropTypes from "prop-types";
import logo from "content/images/logo.svg";

import "./PublicLayout.css";
import ErrorBoundary from "components/Base/ErrorBoundary/ErrorBoundary";
import { Outlet } from "react-router";

export function PublicLayout() {
  return (
    <ErrorBoundary>
    <div className="public-layout">
      <div className="public-layout__content">
        <header className="public-layout__header">
          <img
            src={logo}
            alt="Viedoc Me Logo"
            className="public-layout__header-logo"
            height="24px"
            width="150px"
          />
        </header>
        <main>
          <Outlet />
        </main>
      </div>
    </div>
    </ErrorBoundary>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.any,
};
