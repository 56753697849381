import useSWRMutation from "swr/mutation";
import { http } from "services/http";

export const useSendChangePin = () => {
  const { trigger: sendChangePin, isMutating: isSending } = useSWRMutation(
    "/api/account/change-pin",
    http.put
  );

  return { sendChangePin, isSending };
};
