import React from "react";
import { PropTypes } from "prop-types";
import { NumericRatingScaleRadioButton } from "./NumericRatingScaleRadioButton";
import { TextWithLineBreaks } from "../TextWithLineBreaks/TextWithLineBreaks";

import "./NumericRatingScale.css";

export function NumericRatingScale({
  anchorLabelStart,
  anchorLabelEnd,
  ariaDescribedBy,
  choices,
  id,
  onChange,
  readOnly = false,
  valid = true,
  value,
}) {
  function renderChoice({ label, value: choiceValue }, index) {
    const isFirstChoice = index === 0;
    const isLastChoice = index === choices.length - 1;
    const showIndicatorArrow =
      (isFirstChoice && !!anchorLabelStart) || (isLastChoice && !!anchorLabelEnd);

    return (
      <NumericRatingScaleRadioButton
        ariaDescribedBy={ariaDescribedBy}
        checked={value === choiceValue}
        disabled={readOnly && value !== choiceValue}
        key={choiceValue}
        label={label}
        name={id}
        onChange={onChange}
        showIndicatorArrow={showIndicatorArrow}
        value={choiceValue}
        valid={valid}
      />
    );
  }

  return (
    <div className="numeric-rating-scale">
      {anchorLabelStart && (
        <span className="numeric-rating-scale__anchor-label-start">
          <TextWithLineBreaks text={anchorLabelStart} />
        </span>
      )}
      {anchorLabelEnd && (
        <span className="numeric-rating-scale__anchor-label-end">
          <TextWithLineBreaks text={anchorLabelEnd} />
        </span>
      )}
      <div className="numeric-rating-scale__choices">
        {choices.map(renderChoice)}
      </div>
    </div>
  );
}

NumericRatingScale.propTypes = {
  anchorLabelStart: PropTypes.string,
  anchorLabelEnd: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
