import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Log } from "oidc-client-ts";
import { trackAppInsightsTelemetry, TRACK_METHOD } from "logging/applicationInsightsService";

// The oidc-client-ts library uses the Log class to log messages.
// Here we set it up to use Application Insights for logging.
const oidcTelemetryLogger = {
  debug: (...args) => {
    oidcTelemetryLogger.trace(SeverityLevel.Verbose, ...args);
  },
  info: (...args) => {
    oidcTelemetryLogger.trace(SeverityLevel.Information, ...args);
  },
  warn: (...args) => {
    oidcTelemetryLogger.trace(SeverityLevel.Warning, ...args);
  },
  error: (...args) => {
    oidcTelemetryLogger.trace(SeverityLevel.Error, ...args);
  },
  trace: (severity, ...args) => {
    const message = args
      .map((arg) => (typeof arg === "string" ? arg : JSON.stringify(arg)))
      .join(" ");

    trackAppInsightsTelemetry(
      {
        message: message,
        severityLevel: severity,
      },
      TRACK_METHOD.TRACE
    );
  },
};

Log.setLogger(oidcTelemetryLogger);
Log.setLevel(Log.WARN);
