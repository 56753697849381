import useSWRMutation from "swr/mutation";
import { http } from "services/http";

export const useSendSettings = () => {
  const { trigger: sendSettings } = useSWRMutation(
    "/api/home/settings",
    http.post
  );

  return { sendSettings };
};
