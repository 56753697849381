import "./MainHeader.css";
import logo from "content/images/logo.svg";
import { React, createRef, useState } from "react";
import { Link as RouterLink } from "react-router";
import { useTranslation } from "react-i18next";
import { Button } from "components/Base/Button/Button";
import { MainMenuDrawer } from "components/Layout/MainMenuDrawer";
import { Modal } from "components/Modal/Modal";
import { ReactComponent as MenuIcon } from "content/icons/menu.svg";
import { useSignOut } from "hooks/useSignout";

export function MainHeader() {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const signout = useSignOut();
  const showHelpButton = createRef();

  function openModal() {
    setShowHelp(true);
  }

  function closeModal() {
    showHelpButton.current.focus();
    setShowHelp(false);
  }

  async function handleLogout() {
    await signout();
  };

  return (
    <>
      <header className="main-header">
        <RouterLink to="/" className="main-header__logo">
          <img
            src={logo}
            alt="Viedoc Me Logo"
            className="main-header__logo-image"
            height="24px"
            width="150px"
          />
        </RouterLink>

        <Button
          buttonRef={showHelpButton}
          className="main-header__help-button"
          color="black"
          label={t("general.help.get_help")}
          onClick={openModal}
        />
        <Modal
          isOpen={showHelp}
          onClose={closeModal}
          title={t("general.help.get_help")}
        >
          {t("getHelp.info")}
        </Modal>

        <button
          className="main-header__menu-button"
          onClick={() => setDrawerOpen(true)}
          aria-label="Open menu"
        >
          <MenuIcon className="main-header__menu-button-icon" />
        </button>
      </header>
      <MainMenuDrawer
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onShowHelp={openModal}
        onLogout={handleLogout}
      />
    </>
  );
}
