import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const appInsightsTelemetry = new ReactPlugin();
const connectionString =
  process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;
const environment = process.env.NODE_ENV;

let appInsights = null;
const TRACK_METHOD = Object.freeze({
  METRIC: "metric",
  PAGE_VIEW: "page-view",
  EVENT: "event",
  EXCEPTION: "exception",
  TRACE: "trace"
});

const trackMethods = new Map([
  [TRACK_METHOD.METRIC, (telemetryObject) => appInsightsTelemetry.trackMetric(telemetryObject)],
  [TRACK_METHOD.PAGE_VIEW, (telemetryObject) => appInsightsTelemetry.trackPageView(telemetryObject)],
  [TRACK_METHOD.EVENT, (telemetryObject) => appInsightsTelemetry.trackEvent(telemetryObject)],
  [TRACK_METHOD.EXCEPTION, (telemetryObject) => appInsightsTelemetry.trackException(telemetryObject)],
  [TRACK_METHOD.TRACE, (telemetryObject) => appInsightsTelemetry.trackTrace(telemetryObject)],
]);

if (connectionString) {
  try {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        extensions: [appInsightsTelemetry],
        enableUnhandledPromiseRejectionTracking: true,
        enableAutoRouteTracking: true,
      },
    });

    appInsights.loadAppInsights();
  } catch (error) {
    console.error("Failed to initialize Application Insights", error);
  }
} else {
  if (environment && environment === "production") {
    console.warn(
      "Application Insights connection string not found. Skipping initialization."
    );
  }
}

const trackAppInsightsTelemetry = (telemetryObject, method) => {
  if (appInsightsTelemetry?.isInitialized()) {
    const trackMethod = trackMethods.has(method)
      ? trackMethods.get(method)
      : trackMethods.get(TRACK_METHOD.TRACE);

    trackMethod(telemetryObject);

    return;
  }

  console.log("AppInsightsTelemetryObject", telemetryObject);
};

export { trackAppInsightsTelemetry, TRACK_METHOD };
